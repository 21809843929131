// READ TIME FUNCTION
function readingTime() {
  const text = document.getElementById("article").innerText;
  const wpm = 225;
  const words = text.trim().split(/\s+/).length;
  const time = Math.ceil(words / wpm);
  document.getElementById("reading-time").innerText = time;
}
const blogPost = document.querySelector("#article");
if (blogPost) {
  readingTime();
}

// CLICK & DRAG HORIZONTAL CARD CAROUSEL
const slider = document.querySelector(".carousel-touch>.row");
let isDown = false;
let startX;
let scrollLeft;

if (slider) {
  slider.addEventListener("mousedown", (e) => {
    isDown = true;
    slider.classList.add("active");
    startX = e.pageX - slider.offsetLeft;
    scrollLeft = slider.scrollLeft;
  });
  slider.addEventListener("mouseleave", () => {
    isDown = false;
    slider.classList.remove("active");
  });
  slider.addEventListener("mouseup", () => {
    isDown = false;
    slider.classList.remove("active");
  });
  slider.addEventListener("mousemove", (e) => {
    if (!isDown) return;
    e.preventDefault();
    const x = e.pageX - slider.offsetLeft;
    const walk = (x - startX) * 3; //scroll-fast
    slider.scrollLeft = scrollLeft - walk;
    console.log(walk);
  });
}

// CHARLOTTETOWN LOCATION MAP
// const contactUsPage = document.querySelector(".contact-us.frontend");
// let map;

// async function initMap() {
//   // The location
//   const position = { lat: 46.2338395, lng: -63.1274958 };
//   // Request needed libraries.
//   //@ts-ignore
//   const { Map } = await google.maps.importLibrary("maps");

//   // The map, centered at Uluru
//   map = new Map(document.getElementById("map"), {
//     zoom: 12,
//     center: position,
//     mapId: "58a006a83112bcd",
//     mapTypeControl: false,
//     streetViewControl: false,
//   });

//   // The marker
//   const marker = new google.maps.Marker({
//     map: map,
//     position: position,
//     title: "Charlottetown Office",
//   });
// }

// if (contactUsPage) {
//   initMap();
// }

// DARTMOUTH LOCATION MAP
// let mapDartmouth;

// async function initMapDartmouth() {
//   // The location
//   const position = { lat: 44.6690141, lng: -63.5765985 };
//   // Request needed libraries.
//   //@ts-ignore
//   const { Map } = await google.maps.importLibrary("maps");

//   // The map, centered at Uluru
//   mapDartmouth = new Map(document.getElementById("mapDartmouth"), {
//     zoom: 12,
//     center: position,
//     mapId: "58a006a83112bcd",
//     mapTypeControl: false,
//     streetViewControl: false,
//   });

//   // The marker
//   const marker = new google.maps.Marker({
//     map: mapDartmouth,
//     position: position,
//     title: "Halifax Office",
//   });
// }

// if (contactUsPage) {
//   initMapDartmouth();
// }

// ADD COPY BUTTONS TO CODE EDITORS
let codeEditors = document.querySelectorAll(".code-editor");
for (i = 0; i < codeEditors.length; i++) {
  const codeEditorButton = document.createElement("button");
  codeEditorButton.className = "copy-code";
  codeEditorButton.title = "Copy this code to clipboard";
  codeEditorButton.innerHTML =
    "<span class='tool-tip'>Copied!</span><i class='fa fa-copy'></i>";
  const wrappedContent =
    "<pre class='code-content'>" + codeEditors[i].innerHTML + "</pre>";
  codeEditors[i].innerHTML = wrappedContent;
  codeEditors[i].prepend(codeEditorButton);
  const codeContent = codeEditors[i].children[1].innerText;

  const toolTip = codeEditors[i].children[0];
  codeEditors[i].children[0].addEventListener("click", function () {
    navigator.clipboard.writeText(codeContent);
    toolTip.classList.add("show");
    setTimeout(function () {
      toolTip.classList.remove("show");
    }, 1500);
  });
}

// STRIP EMPTY ATTRIBUTES IM IMG TAG
let tagsWithEmptyStyle = document.querySelectorAll("img,picture");
for (i = 0; i < tagsWithEmptyStyle.length; i++) {
  // let emptyStyleTag = tagsWithEmptyStyle[i].getAttribute("style");
  if (tagsWithEmptyStyle[i].getAttribute("style") === "") {
    tagsWithEmptyStyle[i].removeAttribute("style");
  }
  if (tagsWithEmptyStyle[i].getAttribute("alt") === "") {
    tagsWithEmptyStyle[i].removeAttribute("alt");
  }
}

// REMOVE OUTLINE ON CLICKABLE ITEMS WHEN USING MOUSE
document.body.addEventListener("mousedown", function () {
  document.body.classList.add("using-mouse");
  document.body.classList.remove("using-keyboard");
});

document.addEventListener("scroll", function () {
  document.body.classList.add("using-mouse");
  document.body.classList.remove("using-keyboard");
});

// Re-enable focus styling when Tab is pressed
document.body.addEventListener("keydown", (event) => {
  if (event.code === "Tab" || event.key === "Tab") {
    document.body.classList.remove("using-mouse");
    document.body.classList.add("using-keyboard");
  }
});

// REMOVE TABINDEX ATTRIBUTE OF ALL LINKS IN WYSIWYGs
let copyLinks = document.querySelectorAll("#main-content a");
for (i = 0; i < copyLinks.length; i++) {
  copyLinks[i].removeAttribute("tabindex");
}

// SUBMENU TOGGLE
// var linkToggle = document.querySelectorAll(".menu-item-has-children > a");
var subMenu = document.querySelectorAll(".sub-menu");
// let toggleNum = 1;
// let menuNum = 1;
var subMenuToggle = document.querySelectorAll(".menu-item-has-children > a");
var goBack = document.querySelectorAll(".back-button");

for (i = 0; i < goBack.length; i++) {
  goBack[i].addEventListener("click", function () {
    this.parentElement.classList.toggle("active");
  });
}

for (i = 0; i < subMenuToggle.length; i++) {
  subMenuToggle[i].addEventListener("click", function (e) {
    e.preventDefault();
    this.nextElementSibling.classList.toggle("active");
  });
}

// FORM SUBMISSION MESSAGE
function formSuccessMessage() {
  const submitForm = document.querySelector(".form-submit");
  const ajaxLoader = document.querySelector(".ajax-loader");
  const successMessage = document.querySelector(".success-message");

  if (typeof submitForm !== "undefined" && submitForm !== null) {
    submitForm.addEventListener("click", function (e) {
      e.preventDefault();
      ajaxLoader.classList.add("visible");

      //if(email === null || trim(email) === ''){
      //don't submit form, and maybe display a popup?
      //}

      $.ajax({
        method: "POST",
        url: "/contact-us/submit",
        data: new FormData(submitForm.form),
        processData: false,
        contentType: false,
        success: function (data) {
          document.querySelector(".show-message")?.remove();

          const div = document.createElement("div");
          div.className = "show-message";
          div.innerHTML =
            "<div class='form-message-inner'><div>Thank you for your inquiry. <br>We will be in touch shortly.</div></div>";
          document.body.appendChild(div);

          document.getElementById("inquiry-form").reset();

          setTimeout(function () {
            document.querySelector(".show-message").remove();
          }, 3000);
        },
        complete: function (data) {
          ajaxLoader.classList.remove("visible");
        },
        error: function (data) {
          const div = document.createElement("div");
          div.className = "show-message";
          div.innerHTML =
            "<div class='form-message-inner'><div>Please make sure you check the I&#8127;m not a robot box.</div></div>";
          document.body.appendChild(div);

          setTimeout(function () {
            document.querySelector(".show-message").remove();
          }, 3000);
        },
      });
    });
  }
}

// ADDING TOGGLE LOGIC BASED ON DATA CONTAINER AND DATA IDs
// for (i = 0; i < linkToggle.length; i++) {
//   subMenu[i].setAttribute("id", "toggle-" + menuNum++);
// }

// for (i = 0; i < subLinkToggle.length; i++) {
//   subMenu[i].setAttribute("id", "toggle-" + menuNum++);
// }

// for (i = 0; i < linkToggle.length; i++) {
//   linkToggle[i].setAttribute("data-container", "toggle-" + toggleNum++);
//   linkToggle[i].addEventListener("click", function () {
//     var container = document.getElementById(this.dataset.container);
//     this.children[0].classList.toggle("rotate");

//     if (!container.classList.contains("active")) {
//       container.classList.add("active");
//       container.style.height = "auto";

//       var height = container.clientHeight + "px";

//       container.style.height = "0px";

//       setTimeout(function () {
//         container.style.height = height;
//       }, 0);
//     } else {
//       container.style.height = "0px";

//       container.addEventListener(
//         "transitionend",
//         function () {
//           container.classList.remove("active");
//         },
//         {
//           once: true,
//         }
//       );
//     }
//   });
// }

// REMOVE VIDEO THUMBNAIL ON CLICK
let videoThumb = document.querySelectorAll('.video-overlay');
for (i = 0; i < videoThumb.length; i++) {
  videoThumb[i].addEventListener('click', function () {
    let videoParent = this.parentElement;
    let video = videoParent.children[1];
    // AUTOPLAY VIDEO AFTER THUMBNAIL CLICK
    const autoPlayString = '&autoplay=1&mute=1';
    this.remove();
    video.src += autoPlayString;
  });
}

// THEME TOGGLE
const lightThemeButton = document.querySelector(".light-theme-button");
const darkThemeButton = document.querySelector(".dark-theme-button");
const body = document.querySelector("body");
const theme = window.localStorage.getItem("theme");

if (theme === "dark-theme" || theme === null) {
  body.classList.add("dark-theme");
  darkThemeButton.classList.add("active");
  lightThemeButton.classList.remove("active");
}
if (theme === "light-theme") {
  body.classList.add("light-theme");
  lightThemeButton.classList.add("active");
  darkThemeButton.classList.remove("active");
}

lightThemeButton.addEventListener("click", function () {
  body.classList.add("light-theme");
  body.classList.remove("dark-theme");
  this.classList.add("active");
  darkThemeButton.classList.remove("active");
  window.localStorage.setItem("theme", "light-theme");
});

darkThemeButton.addEventListener("click", function () {
  body.classList.add("dark-theme");
  body.classList.remove("light-theme");
  this.classList.add("active");
  lightThemeButton.classList.remove("active");
  window.localStorage.setItem("theme", "dark-theme");
});

// FIXED HEADER TOGGLE
var lastScrollTop = 0;
const header = document.querySelector("header");

window.addEventListener(
  "scroll",
  function () {
    var st = window.pageYOffset || document.documentElement.scrollTop;
    if (st > lastScrollTop) {
      header.classList.add("scroll-down");
      header.classList.remove("fixed", "scroll-up", "reset");
    } else if (st < lastScrollTop) {
      header.classList.add("fixed", "scroll-up");
      header.classList.remove("scroll-down");
      if (window.pageYOffset == 20) {
        header.classList.add("reset");
      }
    } // else was horizontal scroll
    lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
  },
  false
);

window.onscroll = function () {
  if (window.pageYOffset <= 150) {
    header.classList.add("reset");
  }
};

// OPEN TEAM BIOS IN MODAL
window.onload = function () {
  const btns = document.querySelectorAll(".btn-modal");
  const closeBtn = document.querySelector(".close");
  const modal = document.getElementById("myModal");
  const modalContent = document.querySelector(".modal-content");
  const modalBody = document
    .getElementById("myModal")
    .getElementsByClassName("modal-body")[0];

  // EXITING MODAL
  function exitModal() {
    modal.style.display = "none";
    document.querySelector(".back-drop").remove();
  }

  // RUN FORM SUBMISSION MESSAGE IF FORM EXISTS
  formSuccessMessage();

  // RUN RECAPTCHA ON FORM
  function onSubmit(token) {
    document.getElementById("inquiry-form").submit();
  }

  // MOBILE MENU TOGGLE CHILD MENU ITEMS
  // let parentMenuItem = document.querySelector(".menu-item-has-children");

  // parentMenuItem.addEventListener("click", function () {
  //   this.children[1].classList.toggle("show");
  // });

  // OPENING MODAL
  for (const btn of btns) {
    btn.addEventListener("click", function (e) {
      const btnId = this.getAttribute("id");
      const biosInfo = e.currentTarget.getAttribute("data-bio");
      modalBody.innerHTML = biosInfo;

      document.querySelector('[data-id="' + btnId + '"]').style.display =
        "block";
      modal.style.display = "table";
      modal.insertAdjacentHTML("beforebegin", "<div class='back-drop'></div>");
    });
  }

  // TOGGLE SETTINGS VANILLA JS SOLUTION FOR SETTINGS BUTTON
  // function dynamicSettings() {
  //   this.classList.toggle("reveal-settings");
  //   this.firstChild.classList.toggle("fa-minus");
  //   this.firstChild.classList.toggle("fa-plus");
  // }
  // const toggleSettings = document.querySelectorAll(".toggle-settings");
  // for (const toggleSetting of toggleSettings) {
  //   toggleSetting.onclick = dynamicSettings;
  // }

  (function ($) {
    $(document).on("click", ".toggle-settings", function () {
      $(this).toggleClass("reveal-settings");
      $(this).children("i").toggleClass("fa-minus fa-plus");
    });
  })(jQuery);

  // EXITING MODAL VIA X ICON WITHIN MODAL CONTENT BOX
  closeBtn.addEventListener("click", function () {
    exitModal();
  });

  // EXITING MODAL VIA CLICKING ON OVERLAY SURROUNDING MODAL CONTENT BOX
  document.addEventListener("click", function (e) {
    const target = e.target.closest(".modal");
    if (target) {
      exitModal();
    }
  });

  // DISABLE CLICK ON MODAL CONTENT BOX
  modalContent.addEventListener("click", function (e) {
    e.stopPropagation();
  });

  // EXIT MODAL VIA CLICKING THE ESC BUTTON
  document.onkeydown = function (evt) {
    evt = evt || window.event;
    if (evt.key === 27 || evt.key === "Escape" || evt.key === "ESC") {
      exitModal();
    }
  };

  // HAMBURGER ICON CLICK EVENT
  var icon = document.getElementById("icon");
  var icon1 = document.getElementById("a");
  var icon2 = document.getElementById("b");
  var icon3 = document.getElementById("c");
  var nav = document.querySelector("nav");

  icon.addEventListener("click", function () {
    icon1.classList.toggle("a");
    icon2.classList.toggle("c");
    icon3.classList.toggle("b");
    nav.classList.toggle("show");
    body.classList.toggle("menu-open");
  });
};
